import { Link } from "gatsby";
import { useMemo } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { Meta, Layout, Section } from "~/ui";
import { useStoredQuestions, useStoredReflections } from "~/utils";
import { ResourceType, Resource } from "~/components";
import { Routes } from "~/models";
import { theme } from "~/theme";

import { ReactComponent as Certificate } from "~/assets/icons/license.svg";
import { ReactComponent as BookClosed } from "~/assets/icons/book-closed.svg";
import { ReactComponent as ArrowRightCircle } from "~/assets/icons/arrow-right-circle.svg";
import volunteersWithBuckets from "~/assets/images/volunteers-and-buckets.jpg";
import { css, cx } from "@linaria/core";

export default function Resources(): JSX.Element | null {
  const { areModulesCompleted } = useStoredQuestions();
  const { allReflectionsCompleted } = useStoredReflections();

  const resourceList: ResourceType[] = useMemo(
    () => [
      {
        id: 1,
        type: "download",
        title: "Volunteer Ready Certificate",
        content:
          "Downloadable PDF once you have finished the Getting Ready modules and use to apply for volunteering opportunities.",
        link: Routes.VolunteerReadyCertificate,
        icon: (
          <Certificate width={48} height={48} aria-hidden fill={theme.light} />
        ),
        isExternal: true,
        isLocked: !areModulesCompleted,
        tooltip: BaseToolTip,
      },
      {
        id: 2,
        type: "download",
        title: "Volunteering Reflection",
        content:
          "Downloadable PDF once you have finished the Getting Ready modules.",
        link: Routes.ReflectionsDownload,
        icon: (
          <BookClosed width={48} height={48} aria-hidden fill={theme.light} />
        ),
        isExternal: true,
        isLocked: !allReflectionsCompleted,
        tooltip: BaseToolTip,
      },
      {
        id: 3,
        type: "download",
        title: "CV Builder",
        content:
          "Build and download your resume to use to apply for jobs and volunteering opportunities.",
        link: Routes.CvBuilder,
        icon: (
          <ArrowRightCircle
            width={48}
            height={48}
            aria-hidden
            fill={theme.secondary}
          />
        ),
        isExternal: false,
        isLocked: false,
      },
    ],
    [areModulesCompleted]
  );

  return (
    <Layout>
      <Section
        bgColor="creamGradient"
        baseBgColorTop="primary"
        roundedCorners={{ "top-left": true }}
      >
        <div className="row mb-4">
          <div className="col-12 col-lg-8 mb-4">
            <h1 className="d-flex flex-column text-primary mb-4">
              Job
              <span className="display-1">Resources</span>
            </h1>
            <p>
              Volunteering can be an effective pathway to employment as it helps
              you build skills that employers value. These include
              communication, administration, organisation and technical, as well
              as demonstrating a commitment to a worthy cause. Use these job
              ready resources to help you leverage your volunteering experience.
            </p>
          </div>
          <div className="col-12 col-lg-4 text-center mt-lg-n5 mb-4">
            <img
              src={volunteersWithBuckets}
              alt="Two youth volunteers collecting marine seeds as part of a beach volunteering day"
              className="img-fluid"
            />
          </div>
        </div>

        <div className="row">
          {resourceList.map((resource) => (
            <div key={resource.id} className="col-12 col-lg-4 mb-4">
              {resource.isLocked ? (
                <OverlayTrigger
                  key={`${resource.id}-tooltip`}
                  placement="top"
                  trigger="click"
                  overlay={<Tooltip>{resource.tooltip}</Tooltip>}
                >
                  <article className="h-100">
                    <Resource {...resource} />
                  </article>
                </OverlayTrigger>
              ) : (
                <Resource {...resource} />
              )}
            </div>
          ))}
        </div>
      </Section>

      <Section
        bgColor="violet200"
        className={cx(
          css`
            padding: 5rem 0;
          `
        )}
      >
        <div className="text-center">
          <h2 className="h4 text-primary font-weight-normal mb-1">
            Have a question?
          </h2>
          <p className="mb-0">
            Check out <Link to={`${Routes.GettingReady}#faqs`}>our FAQ's</Link>.
          </p>
        </div>
      </Section>
    </Layout>
  );
}

export const Head = () => {
  return <Meta title="Job Ready - Resources" url={Routes.Resources} />;
};

const BaseToolTip = (
  <>
    Complete our short{" "}
    <Link to={Routes.GettingReady} className="text-reset text-underline">
      online&nbsp;course
    </Link>{" "}
    to unlock your certificate
  </>
);
